<template>
  <div class="container">
    <c-header lang="English" />
    <div class="signin">
      <div class="banner">
        <h2>大势所趋，随虚而动</h2>
      </div>
      <div class="form-container">
        <hgroup>
          <h3>Please Login</h3>
          <h4>登录平台</h4>
        </hgroup>
        <van-form>
            <van-field
              v-model="form.phone"
              type="tel"
              name="手机号"
              left-icon="phone"
              label="+86"
              placeholder="请输入手机号"
              autocomplete="off"
              autofocus="autofocus"
              @click-input="handleFieldClick"
              :rules="[{ required: true,pattern: regPhone, message: '请输入正确的手机号' }]"
            >
              <van-icon class="iconfont" class-prefix="icon" slot="left-icon" name="phone"></van-icon>
            </van-field>
            <van-field
              v-model="form.smsCode"
              type="digit"
              name="验证码"
              left-icon="code"
              label=""
              placeholder="请输入验证码"
              autocomplete="off"
              maxlength="6"
              @click-input="handleFieldClick"
              :rules="[{ required: true,pattern: regSmsCode, message: '请输入正确的验证码' }]"
            >
              <van-icon class="iconfont" class-prefix="icon" slot="left-icon" name="code"></van-icon>
              <template #button>
                <div class="get-code"
                     :class="{disabled: codeDisable}"
                     @click="handleGetCode"
                >{{codeText}}
                </div>
              </template>
            </van-field>
            <div class="submit">
              <van-button round block
                          type="info"
                          @click="handleSignin"
                          :loading="loading"
                          :disabled="_getSubmitDisabled()"
              >
                登录
              </van-button>
            </div>
            <a class="to-signup" @click.prevent="toSignup">
              <span>去注册</span>
              <svg viewBox="0 0 3276 1024" class="more">
                <path :d="svgPath.more"></path>
              </svg>
            </a>
          </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import header from '~/components/header'
import { mapActions } from 'vuex'
import pageMixin from '@/mixins/pageMixin'
import accountMixin from '@/mixins/accountMixin'
export default {
  mixins: [pageMixin, accountMixin],
  components: {
    cHeader: header
  },
  data () {
    return {
      form: {
        phone: '',
        smsCode: ''
      },
      loadingCode: false,
      loading: false
    }
  },
  mounted () {
    try {
      this.$ls.set('test', 'tempTestStorage')
      if (!this.$ls.get('test')) {
        this.$toast.fail('请关闭无痕模式，否则将无法正常登录')
      }
    } catch (e) {
      this.$toast.fail('请关闭无痕模式，否则将无法正常登录')
    }
  },
  methods: {
    ...mapActions(['signin']),
    handleFieldClick (e) {
      e.target.focus()
    },
    toSignup () {
      this.$router.push({
        path: '/account/signup',
        query: this.$route.query
      })
    },
    async handleSignin () {
      this.loading = true
      try {
        await this.signin(this.form)
        const { redirect = '/' } = this.$route.query || {}
        this.$router.replace(redirect)
      } catch (e) {
        return false
      } finally {
        this.loading = false
      }
    },
    async handleGetCode () {
      if (this.codeDisable || this.loadingCode) return false
      this.loadingCode = true
      const { phone } = this.form
      try {
        await this.api.getSms(phone)
        this._startCodeTimer()
      } catch (e) {
        return false
      } finally {
        this.loadingCode = false
      }
    }
  }
}
</script>

<style lang="less">
  .signin{
    .banner{
      .flex;
      width: 100%;
      height: 494px;
      margin-top: -@header-height;
      background-image: url("~@/assets/images/account/banner.jpg");
      .full_bg;
      color: #fff;
      h2{
        font-size: 62px;
        margin-top:@header-height;
      }
    }
    .form-container{
      position: relative;
      padding: 74px 65px 260px;
      &::before,&::after{
        position: absolute;
        z-index: 0;
        width: 365px;
        height: 192px;
        content: '';
        display: none;
        background: url("~@/assets/images/account/bg_dot.png");
      }
      &::before{
        right: 0;
        top: 0;
      }
      &::after{
        width: 319px;
        height: 292px;
        left: 0;
        bottom: 12px;
      }
      hgroup{
        margin-bottom: 63px;
        h3{
          margin-bottom: 15px;
          font-size: 48px;
          color: @blue;
          font-weight: 600;
        }
        h4{
          font-size: 36px;
          color: @grey1;
        }
      }
      .to-signup{
        position: relative;
        z-index: 1;
        .flex(@justify: flex-start);
        width: 30%;
        color: @blue;
        font-size: 28px;
        margin-top: 50px;
        svg{
          width: 32px;
          height: 11px;
          margin-left: 15px;
          fill: @blue;
        }
      }
    }
  }
</style>
